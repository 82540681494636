<template>
  <div v-frag>
    <v-container class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="$router.push({ name: 'admin-career-jobs-edit', params: { jobId: 'uj-allashirdetes' }})">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
            v-model="search"
            aria-label="Keresés"
            label="Keresés"
            filled
            prepend-inner-icon="mdi-magnify"
            persistent-hint
            clearable
            hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="jobs"
        :search="search"
        :loading="loadingData"
        loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
        no-data-text="Nincsenek szerkeszthető álláshirdetések!"
        :no-results-text="`A keresett ${search} kifejezés nem található`"
        :items-per-page="25"
      >
        <template v-slot:item.status="{item}">
          <v-chip small
                  :color="item.isActive ? 'success' : 'error'">
            {{ item.isActive ? $t('active') : $t('closed') }}
          </v-chip>
        </template>
        <template v-slot:item.validFrom="{ item }">
          {{ item.validFrom.substr(0, 10) }}
        </template>
        <template v-slot:item.validTo="{ item }">
          {{ item.validTo.substr(0, 10) }}
        </template>
        <template v-slot:item.actions="{item}">
          <!--v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on"
                      @click="copyPage(item.id)">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Álláshirdetés duplikálása</span>
          </v-tooltip-->
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editItem(item.id)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Álláshirdetés szerkesztése</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on"
                      @click="viewJobInBrowser(item.translation.url)">
                mdi-eye
              </v-icon>
            </template>
            <span>Álláshirdetés megtekintése</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="showVisitors(item.id)"
                      v-on="on">
                mdi-account
              </v-icon>
            </template>
            <span>Jelentkezők megtekintése</span>
          </v-tooltip>
          <!--v-tooltip top v-if="item.isErasable">
            <template v-slot:activator="{on}">
              <v-icon @click="editItem(item.id)"
                      v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Oldal törlése</span>
          </v-tooltip-->
        </template>
      </v-data-table>
    </v-container>
    <!-- SNACKBAR START -->
    <v-snackbar v-model="copyPageSnack.isVisible"
                :timeout="copyPageSnack.timeout"
                :color="copyPageSnack.isSuccess ? 'success' : 'error'">
      {{ $t(copyPageSnack.message) }}
    </v-snackbar>
    <!-- SNACKBAR END -->
  </div>
</template>

<script>
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export default {
  name: 'CareerJobsPage',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    this.jobs = await this.loadTableData();
    this.addStatusToJobs();
    this.$store.commit('deleteFrontEndActualJob');
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Név', value: 'translation.title' },
        { text: 'Státusz', value: 'status' },
        { text: 'Kezdő dátum', value: 'validFrom' },
        { text: 'Vég dátum', value: 'validTo' },
        { text: 'Szervezet', value: 'division.translation.name' },
        { text: '', value: 'actions' },
      ],
      jobs: [],
      loadingData: true,
      copyPageSnack: {
        isVisible: false,
        isSuccess: true,
        message: 'SUCCESS_MESSAGES.PAGE_COPY',
        timeout: 2000,
      },
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('showAllJobs');
      // console.log('CareerJobsPage response: ', response);
      if (response.status === 200) {
        return response.data.sort((a, b) => ((a.validFrom < b.validFrom) ? 1 : -1));
      }
      return [];
    },
    addStatusToJobs() {
      const today = new Date();
      for (let i = 0; i < this.jobs.length; i++) {
        this.jobs[i].isActive = dayjs(today).isBefore(dayjs(this.jobs[i].validTo));
      }
    },
    editItem(jobId) {
      this.$router.push({ name: 'admin-career-jobs-edit', params: { jobId } });
    },
    showVisitors(jobId) {
      this.$router.push({ name: 'admin-career-jobs-visitors', params: { jobId } });
    },
    async copyPage(pageId) {
      const response = await this.$store.dispatch('copyPage', pageId);
      // console.log('copyPage: ', response);
      if (response.status === 200) {
        window.location.reload();
      } else {
        this.copyPageSnack.message = 'ERROR_MESSAGES.PAGE_COPY';
        this.copyPageSnack.isSuccess = false;
      }
      this.copyPageSnack.isVisible = true;
    },
    viewJobInBrowser(url) {
      window.open(`/${url}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>
