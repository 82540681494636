var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('v-container',{staticClass:"py-10"},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-career-jobs-edit', params: { jobId: 'uj-allashirdetes' }})}}},[_vm._v(" "+_vm._s(_vm.$t('button.addNew'))+" ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"aria-label":"Keresés","label":"Keresés","filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":"Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jobs,"search":_vm.search,"loading":_vm.loadingData,"loading-text":"Adatok betöltése folyamatban... Kérjük várjon!","no-data-text":"Nincsenek szerkeszthető álláshirdetések!","no-results-text":("A keresett " + _vm.search + " kifejezés nem található"),"items-per-page":25},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.isActive ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.isActive ? _vm.$t('active') : _vm.$t('closed'))+" ")])]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.validFrom.substr(0, 10))+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.validTo.substr(0, 10))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Álláshirdetés szerkesztése")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.viewJobInBrowser(item.translation.url)}}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Álláshirdetés megtekintése")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.showVisitors(item.id)}}},on),[_vm._v(" mdi-account ")])]}}],null,true)},[_c('span',[_vm._v("Jelentkezők megtekintése")])])]}}])})],1),_c('v-snackbar',{attrs:{"timeout":_vm.copyPageSnack.timeout,"color":_vm.copyPageSnack.isSuccess ? 'success' : 'error'},model:{value:(_vm.copyPageSnack.isVisible),callback:function ($$v) {_vm.$set(_vm.copyPageSnack, "isVisible", $$v)},expression:"copyPageSnack.isVisible"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.copyPageSnack.message))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }